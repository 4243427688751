import './index.css';
import './reset.css';

import { ThemeExtensionContainer } from '@goshippo/components';
import { FullStoryProvider } from '@goshippo/utilities';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';

import { FeatureFlagsProvider } from '~/src/app/utils/featureflag';
import { optimizelyClient } from '~/src/app/utils/featureflag/FeatureFlagsProvider';
import { AnalyticsFiltersProvider } from '~/src/contexts/AnalyticsContext';
import { ReportStatusProvider } from '~/src/contexts/ReportStatusContext';
import { environment } from '~/src/utils/environment';

import { AuthProvider } from './AuthProvider';
import { AuthTokenSetup } from './AuthTokenSetup';
import { ErrorBoundary } from './ErrorBoundary';
import { LocalAuthentication } from './LocalAuthentication';
import { MicroFrontendProvider } from './MicroFrontendProvider';
import { QueryProvider } from './QueryProvider';
import { Routes } from './Routes';
import { SessionExpiredProvider } from './SessionExpiredProvider';
import { SnackbarProvider } from './SnackbarProvider';

export const App = () => (
  <ErrorBoundary>
    <BrowserRouter>
      <AuthProvider>
        <AuthTokenSetup>
          <SessionExpiredProvider>
            <QueryProvider>
              <ReactQueryDevtools />
              <ThemeExtensionContainer>
                <FeatureFlagsProvider optimizelyClient={optimizelyClient}>
                  <FullStoryProvider
                    fullStoryOpts={{
                      debug: environment.FULL_STORY_DEBUG === 'true',
                      devMode: environment.FULL_STORY_DEVMODE === 'true',
                      host: environment.FULL_STORY_HOST,
                      namespace: environment.FULL_STORY_NAMESPACE,
                      orgId: environment.FULL_STORY_ORGID,
                      script: environment.FULL_STORY_SCRIPT,
                    }}
                  >
                    <SnackbarProvider>
                      <MicroFrontendProvider>
                        <ReportStatusProvider>
                          <AnalyticsFiltersProvider>
                            <Routes />
                          </AnalyticsFiltersProvider>
                          <LocalAuthentication />
                        </ReportStatusProvider>
                      </MicroFrontendProvider>
                    </SnackbarProvider>
                  </FullStoryProvider>
                </FeatureFlagsProvider>
              </ThemeExtensionContainer>
            </QueryProvider>
          </SessionExpiredProvider>
        </AuthTokenSetup>
      </AuthProvider>
    </BrowserRouter>
  </ErrorBoundary>
);
