import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './app/components/App';
import { setup } from './app/utils/setup/setup';

declare global {
  interface Window {
    APPCUES_TEST_IDENTITY_VERIFICATION: boolean;
    Appcues: {
      group: (
        groupId: string,
        groupProperties?: { [key: string]: boolean | number | string },
      ) => void;
      identify: (
        userId: string,
        userProperties?: { [key: string]: boolean | number | string },
      ) => void;
      page: () => void;
      track: (
        eventName: string,
        eventProperties?: { [key: string]: boolean | number | string },
      ) => void;
    };
    AppcuesSettings: {
      enableURLDetection: boolean;
      userIdSignature: string;
    };
  }
}

setup();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
