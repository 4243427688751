import { createContext, useContext, useEffect, useState } from 'react';

// control ids configured in Sigma
type DashboardFilters = {
  'Address-From-Country-From-Name': string;
  'Address-to-Country-to-Name': string;
  'Partner-Merchant': string;
  'Provider-Name': string;
  'Rate-Servicelevel-Name': string;
  'Transaction-Object-Created': string;
};

type AnalyticsFiltersContextType = {
  filters: DashboardFilters;
  updateFilters: (filters: Record<string, string>) => void;
};

const AnalyticsFiltersContext = createContext<AnalyticsFiltersContextType>(
  {} as AnalyticsFiltersContextType,
);

export const AnalyticsFiltersProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = useState<DashboardFilters>({} as DashboardFilters);

  const updateFilters = (filters: Record<string, string>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...filters,
    }));
  };

  // clear previous session storage values on load
  useEffect(() => {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.endsWith('SelectedPage') || key.endsWith('SwitchValue')) {
        sessionStorage.removeItem(key);
      }
    });
  }, []);

  return (
    <AnalyticsFiltersContext.Provider value={{ filters, updateFilters }}>
      {children}
    </AnalyticsFiltersContext.Provider>
  );
};

export const useAnalyticsFilters = () => {
  const context = useContext(AnalyticsFiltersContext);
  if (!context) {
    throw new Error('useAnalyticsFilters must be used within an AnalyticsFiltersProvider');
  }
  return context;
};
