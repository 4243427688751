import { z } from 'zod';

import { HttpClient } from '~/src/clients/http/HttpClient';
import { djangoHttpClient } from '~/src/clients/shippo/DjangoHttpClient';

const TOKEN_SCHEMA = z.object({
  token: z.string(),
});

export type AppcuesResponse = z.infer<typeof TOKEN_SCHEMA>;

export class AppcuesService {
  getAppcuesToken = async () => {
    const { body } = await this.httpClient.get<AppcuesResponse>({
      url: '/appcues/jwt',
    });

    const token = TOKEN_SCHEMA.parse(body);

    return token;
  };

  constructor(private readonly httpClient: HttpClient = djangoHttpClient) {}
}

export const appcuesService = new AppcuesService();
