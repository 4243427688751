import { VariationTypes } from './variationTypes';

type ValueOf<T> = T[keyof T];

export type FeatureFlag = {
  name: string;
  variationType: ValueOf<typeof VariationTypes>;
};

// Names must match the feature flag names in Optimizely
export const FeatureFlags = {
  ANALYTICS_REPORTS: {
    name: 'portal_analytics_reports',
    variationType: VariationTypes.ROLLOUT,
  },
  BETA_REPORTS: {
    name: 'portal_beta_reports',
    variationType: VariationTypes.ROLLOUT,
  },
  DHL_GERMANY_SUPPORT_DEPRECATED: {
    name: 'carrier_support_deprecated',
    variationType: VariationTypes.ROLLOUT,
  },
  FEDEX_NEW_API_ENABLED: {
    name: 'fedex_new_api_enabled_ui',
    variationType: VariationTypes.ROLLOUT,
  },
  SIMULATION: {
    name: 'portal_insights_simulation',
    variationType: VariationTypes.ROLLOUT,
  },
  SURCHARGES_REPORTS: {
    name: 'portal_surcharge_reports',
    variationType: VariationTypes.ROLLOUT,
  },
  UPS_OAUTH_CREATE_ACCOUNT: {
    name: 'ups_oauth_create_account',
    variationType: VariationTypes.ROLLOUT,
  },
  USPS_OAUTH_UI: {
    name: 'usps_oauth_ui',
    variationType: VariationTypes.ROLLOUT,
  },
  USPS_OAUTH_UI_PARTNER_MASTER: {
    name: 'usps_oauth_ui_partner_master',
    variationType: VariationTypes.ROLLOUT,
  },
} as const satisfies Record<string, FeatureFlag>;
