import { useEffect, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { newRelic } from '../clients/newRelic/NewRelicClient';
import { appcuesService } from '../services/AppcuesService';
import { useUserAccount } from './useUserAccount';

interface IdentifyProps {
  displayName?: string;
  email?: string;
  userId?: number;
}

export const usePortalAppcues = () => {
  const Appcues = window.Appcues;
  const [isIdentified, setIsIdentified] = useState(false);
  const { data, status } = useUserAccount();

  const { data: appcuesTokenData, error } = useQuery({
    enabled: !!data,
    queryFn: () => appcuesService.getAppcuesToken(),
    queryKey: ['appcues_signature'],
  });

  const identifyProps: IdentifyProps | null = useMemo(
    () => ({
      displayName: `${data?.firstName} ${data?.lastName}`,
      email: data?.email,
      userId: data?.id,
    }),
    [data],
  );

  useEffect(() => {
    if (error) newRelic.noticeError(error as Error, { id: 'AppcuesMonitor' });

    if (Appcues && !isIdentified && identifyProps && status === 'success' && appcuesTokenData) {
      window.AppcuesSettings = {
        enableURLDetection: true,
        userIdSignature: appcuesTokenData.token,
      };

      const { userId, ...restIdentifyProps } = identifyProps;
      Appcues.identify(String(userId), restIdentifyProps);

      setIsIdentified(true);
    }
  }, [isIdentified, identifyProps, Appcues, status, appcuesTokenData, error]);
};
