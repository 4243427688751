import { lazy } from 'react';

import { AnalyticsOutlined as AnalyticsIcon } from '@goshippo/components';

import { Navigate, Route, Routes } from 'react-router-dom';

import { NavigationMenuCategory } from '~/src/app/components/Layout/components/NavigationMenu';
import { FeatureFlags } from '~/src/app/utils/featureflag/featureFlags';
import { useFeatureFlag } from '~/src/app/utils/featureflag/useFeatureFlag';
import { NavigationMenuIcon } from '~/src/components/NavigationMenuIcon';
import { useAuthentication } from '~/src/hooks/useAuthentication';
import { useUserEntitlements } from '~/src/hooks/useUserEntitlements';

const KPI = lazy(() => import('./pages/KPI'));
const LabelsPurchased = lazy(() => import('./pages/LabelsPurchased'));
const UnsuccessfulDeliveries = lazy(() => import('./pages/UnsuccessfulDeliveries'));
const TransitTime = lazy(() => import('./pages/TransitTime'));
const ShippingSpend = lazy(() => import('./pages/ShippingSpend'));
const Surcharges = lazy(() => import('./pages/Surcharges'));

export const AnalyticsRoutes = () => {
  const {
    entitlements: { isStaff },
  } = useUserEntitlements();

  const { isImpersonating } = useAuthentication();

  const analyticsReportsFeatureFlag = useFeatureFlag(FeatureFlags.ANALYTICS_REPORTS);
  const analyticsReportsEnabled = analyticsReportsFeatureFlag?.enabled || false;

  const showAnalyticsReports = isStaff || isImpersonating || analyticsReportsEnabled;

  if (!showAnalyticsReports) return <Navigate to="/" />;

  return (
    <Routes>
      <Route element={<KPI />} path="kpi-dashboard" />
      <Route element={<LabelsPurchased />} path="labels-purchased" />
      <Route element={<UnsuccessfulDeliveries />} path="unsuccessful-deliveries" />
      <Route element={<TransitTime />} path="transit-time" />
      <Route element={<ShippingSpend />} path="shipping-spend" />
      <Route element={<Surcharges />} path="surcharges" />
      <Route element={<Navigate to="kpi-dashboard" />} path="*" />
    </Routes>
  );
};

export const analyticsNavigationCategory: NavigationMenuCategory = {
  beta: true,
  icon: <NavigationMenuIcon icon={AnalyticsIcon} />,
  items: [
    {
      name: 'analytics.items.kpi',
      route: '/analytics/kpi-dashboard',
    },
    {
      name: 'analytics.items.labelsPurchased',
      route: '/analytics/labels-purchased',
    },
    {
      name: 'analytics.items.unsuccessfulDeliveries',
      route: '/analytics/unsuccessful-deliveries',
    },
    {
      name: 'analytics.items.transitTime',
      route: '/analytics/transit-time',
    },
    {
      name: 'analytics.items.shippingSpend',
      route: '/analytics/shipping-spend',
    },
    {
      name: 'analytics.items.surcharges',
      route: '/analytics/surcharges',
    },
  ],
  name: 'analytics.title',
};
