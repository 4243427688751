export const loqateGetAddressUrl =
  'https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws';

export const loqateSearchAddressUrl =
  'https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws';

export const promoWithTerms = [
  'DEFAULT_EXTENDED_TRIAL',
  '2FREEPRO',
  '3MONTHSFREENOW',
  '3MONTHSFREESUMMER',
  '6FREEPROFS',
  'BREX4MONTHSFREE',
  'FREESTYLE1000',
  'NOLABELFEES',
  'SHIP2TEE',
  'SHIP150',
  'SHIPHERO1000',
  'USPSREFERRAL',
  'NEXTSTOPSHIPPO',
  'USPSREP01',
  'WEEBLYTEN',
  'PROMOEXPIRED',
  'SHIPHAPPY100',
  '25FOR25',
  'SIENNA',
  'NATALI',
  'WYATT',
  'EMILY',
  '3MONTHPRO',
  'BIGCOMMERCE25',
  'GOALDIGGER',
];

export const CREDIT_CARD_CONVENIENCE_FEE = 'CREDIT_CARD_CONVENIENCE_FEE';

export const ACH_DISCOUNT = 'ACH_DISCOUNT';
